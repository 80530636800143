import React, { useState, useRef, useEffect } from "react"
import { css } from "@emotion/react"
import styled from "@emotion/styled"
import MailchimpSubscribe from 'react-mailchimp-subscribe'
import { useSubscribeRef } from "~context/siteContext"

const StyledInput = styled("input")`
    color: var(--black);
    background: transparent;
    border: none;
    -webkit-appearance: none;
    flex: 1;
    padding-left: 0px;
    margin-right: 0.5rem;
    ::placeholder {
        font-size: 14px;
        color: var(--black);
    }
`

const StyledButton = styled("button")`
    padding: 1px 0px;
    font-size: 14px;
`



const CustomForm = ({ status, message, onValidated }) => {

    const [showing, setShow] = useState(false);

    const { setSubscribeRef } = useSubscribeRef()
    const subscribeRef = useRef()

    useEffect(() => {
      setSubscribeRef(subscribeRef)
    }, [])

    let email;
    const submit = () =>
      email &&
      email.value.indexOf("@") > -1 &&
      onValidated({
        EMAIL: email.value
      });

    return (
      <div
      ref={subscribeRef}
      css={css`
        border-bottom: 2px solid #fff;
        width: 100%;
        display: flex;
        padding-bottom: 3px;

        input{
          font-family: inherit;
        }
      `}>
        <StyledInput
          ref={node => (email = node)}
          type="email"
          placeholder="Email address"
          onFocus={() => setShow(true)}
          onBlur={() => setShow(false)}
        />
        <br />
        <StyledButton onClick={submit} css={css`opacity: ${showing ? '1' : '0'};`}>
            Submit
        </StyledButton>
      </div>
    );
  };



  function SubscribeForm() {

    const mcUrl = "//composite.us7.list-manage.com/subscribe/post?u=184dce95597b2ce050033d474&id=8716303cab";

    return (
        <MailchimpSubscribe
            url={mcUrl}
            render={({ subscribe, status, message }) => (
            <div className="form-wrap subscribe-form" css={css`margin-top: 4px;`}>
                <CustomForm onValidated={formData => subscribe(formData)} />
                <div className="response" css={css`font-style: italic;`}>
                {status === "sending" && <div>sending...</div>}
                {status === "error" && !(message.indexOf('is already subscribed') > 0) &&  <div dangerouslySetInnerHTML={{__html: message}}/>}
                {(status === "success" || message?.indexOf('is already subscribed') > 0) && <div>done.</div>}
                </div>
            </div>
            )}
        />
      )

  }

  export default SubscribeForm;
