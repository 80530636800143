import React, { useState } from "react"
import { css } from "@emotion/react"
import styled from "@emotion/styled"
import { noCase } from "change-case"
import { Link } from "gatsby"

import { Logo, CompactLogo } from "~components/svg"
import { Grid, breakpoints } from "~styles/global"
import { useSiteState, useFilter } from "~context/siteContext"
import Search from "./search"
import { useLocation } from "@reach/router"

const { tablet } = breakpoints

const HeaderWrap = styled("header")`
  padding: 1rem 1rem 0.5rem 1rem;
  display: grid;
  grid-template-columns: calc(140px + 0.5rem) 1fr;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  transition: background-color 1s;
  background: var(--grey);
  ${tablet}{
    display: grid;
    grid-template-columns: 70px 1fr;
    &:after{
      content: "";
      height: 2px;
      position: absolute;
      bottom: 9px;
      left: 1rem;
      right: 1rem;
      background: var(--white);
      display: block;
    }
  }
  svg{
    width: 140px;
    display: block;
    ${tablet}{
      width: 100%;
    }
  }
`

const Line = styled("div")`
  height: 2px;
  width: 100%;
  background: var(--black);
  align-self: end;
`

const FilterWrap = styled("div")`
  grid-column: 4 / 5;
`

const NavLink = styled(Link)`
    height: 100%;
    opacity: 0.5;
`

const Nav = () => {
  let [siteState] = useSiteState()
  let pageTitle = siteState.pageTitle
  const { setSort, sort, setShow, show, setSearchTerm } = useFilter()
  const [hoverTitle, setHoverTitle] = useState(null)
	const loc = useLocation()

  pageTitle = pageTitle ?? 'Composite'

  const resetFilters = () => {
    setShow(null)
    // setSearchTerm(null)
  }

	const refreshIfHome = () => {
		if(loc.pathname === "/"){
			loc.reload()
		}
	}

  if(!pageTitle) return null
  return(
    <nav css={css`
        position: relative;
        ${tablet}{
          display: none;
        }
      `}>
      <Logo letter={ hoverTitle ? hoverTitle[0] : 'all' } loading={siteState.loading}/>
      <div css={css`
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          left: 0;
          display: flex;
          flex-direction: column;
        `}>
      <NavLink to="/" onMouseEnter={() => setHoverTitle('home')} onMouseLeave={() => setHoverTitle(null)} onClick={() => refreshIfHome()}></NavLink>
      <NavLink to="/collection" onMouseEnter={() => setHoverTitle('collection')} onMouseLeave={() => setHoverTitle(null)}></NavLink>
      <NavLink to="/watch" onMouseEnter={() => setHoverTitle('watch')} onMouseLeave={() => setHoverTitle(null)}></NavLink>
      <NavLink to="/discourse" onMouseEnter={() => setHoverTitle('discourse')} onMouseLeave={() => setHoverTitle(null)}></NavLink>
      <NavLink to="/news" onMouseEnter={() => setHoverTitle('news')} onMouseLeave={() => setHoverTitle(null)}></NavLink>
      <NavLink to="/program" onMouseEnter={() => setHoverTitle('program')} onMouseLeave={() => setHoverTitle(null)}></NavLink>
      <NavLink to="/live" onMouseEnter={() => setHoverTitle('live')} onMouseLeave={() => setHoverTitle(null)}></NavLink>
      <NavLink to="/about" onMouseEnter={() => setHoverTitle('about')} onMouseLeave={() => setHoverTitle(null)}></NavLink>
      <NavLink to="/support" onMouseEnter={() => setHoverTitle('support')} onMouseLeave={() => setHoverTitle(null)}></NavLink>
      </div>
      <h1 css={css`
        position: absolute;
        top: 0;
        right: 0;
        transform: translateX(100%);
        width: 500%;
        max-width: 50vw;
      `}>{hoverTitle ? (!siteState.loading && (hoverTitle).substring(1)) : (!siteState.loading && (pageTitle).substring(1))}</h1>
    </nav>
  )
}

const MobileNav = () => {
  let [siteState] = useSiteState()
  let pageTitle = siteState.pageTitle
  const [open, setOpen] = useState(false)
  return(
    <>
    <button onClick={() => setOpen(!open)} css={css`
        display: none;
        position: relative;
        &:focus{
          outline: none;
        }
        ${tablet}{
          display: block;
        }
      `}>
      <CompactLogo css={css`
        height: 100%;
        position: ${open ? 'absolute' : 'relative'};
        top: 0;
        svg{
          height: ${open ? 'calc(100% - 14px)' : 'auto'};
        }
        `} />
    </button>
    <div css={css`
        margin-left: 0.4rem;
        display: none;
        ${tablet}{
          display: ${open ? 'none' : 'block'};
        }
      `}>
      <h1 className="h2">{pageTitle?.substring(1)}</h1>
    </div>
    <div className="h2" css={css`
        margin-left: 0.4rem;
        display: none;
        line-height: 1.1;
        ${tablet}{
          display: ${open ? 'block' : 'none'};
        }
      `}>
      <Link to="/">ome</Link><br/>
      <Link to="/collection">ollection</Link><br/>
      <Link to="/watch">atch</Link><br/>
      <Link to="/discourse">iscourse</Link><br/>
      <Link to="/news">ews</Link><br/>
      <Link to="/program">rogram</Link><br/>
      <Link to="/live">ive</Link><br/>
      <Link to="/about">bout</Link><br/>
      <Link to="/support">upport</Link>
    </div>
    </>
  )
}

const Header = ({ siteTitle }) => {
  let [siteState] = useSiteState()
  return (
    <HeaderWrap>
      <Nav/>
      <MobileNav />
      <Grid css={css`
        ${tablet}{
          display: none;
        }
        `}>
        {siteState.filterShowing &&
          <FilterWrap className="filters">
            <Search />
          </FilterWrap>
        } 
        <Line/>
        <Line/>
        <Line/>
        <Line/>
      </Grid>
    </HeaderWrap>
  )
}

export default Header
