const resolveLink = ref => {
  if(!ref) return null
  switch (ref._type) {
    case 'textDoc':
      return `/text/${ref.slug.current}`
    case 'page':
      return `/${ref.slug.current}`
    case 'about':
      return '/about'
    case 'support':
      return '/support'
    case 'newsPage':
      return `/${ref.slug.current}`
    case 'livePage':
      return `/${ref.slug.current}`
    case 'programPage':
      return `/${ref.slug.current}`
    case 'watchPage':
      return `/${ref.slug.current}`
    case 'discoursePage':
      return `/${ref.slug.current}`
    case 'programPage':
      return `/${ref.slug.current}`
    case 'collectionPage':
      return `/${ref.slug.current}`
    default:
      return `/${ref._type}/${ref.slug.current}`
  }
}

export default resolveLink
