import { Link } from "gatsby"
import React from "react"
import { css } from "@emotion/react"
import styled from "@emotion/styled"
import resolveLink from "~utils/resolveLink"
import { breakpoints, Grid } from "../styles/global"
import SubscribeForm from "./subscribeForm"
import { useStaticQuery, graphql } from "gatsby"

const { mobile } = breakpoints

const FooterWrap = styled("footer")`
  display: grid;
  grid-template-columns: 2fr;
  font-size: 14px;
  margin-bottom: 1rem;
  padding-top: 0.5rem;
  margin-top: auto;
  ul {
    list-style-type: none;
    li {
      margin: 0px;
    }
  }
`
const Line = styled("div")`
  height: 2px;
  width: 100%;
  background: var(--black);
  align-self: end;
`

function Footer ({ siteTitle }) {

    const { sanitySiteSettings } = useStaticQuery(
      graphql`
        query {
          sanitySiteSettings(id: {eq: "-0f217bb5-f7f6-5420-b7c6-58db2c12b8c7"}) {
            _rawSocialImage(resolveReferences: {maxDepth: 2})
            contactEmail
            openingHours
            address
            phoneNumber
            openingHours
            discoverLinks {
            ... on SanityExternalLink {
              _key
              _type
              linkText
              url
              }
            ... on SanityInternalLink {
              _key
              _type
              linkText
              _rawTo(resolveReferences: {maxDepth: 10})
              }
            }
            followUsLinks {
              ... on SanityExternalLink {
                _key
                _type
                linkText
                url
              }
            }
            informationLinks {
              ... on SanityExternalLink {
                _key
                _type
                linkText
                url
              }
              ... on SanityInternalLink {
                _key
                _type
                linkText
                _rawTo(resolveReferences: {maxDepth: 10})
              }
            }
          }
        }
      `
    )
    return (

    <FooterWrap>
      <Grid  css={css`grid-template-columns: repeat(4, minmax(0, 1fr));`}>
        <Line css={css`
          ${mobile}{
            grid-column: 1/5;
          }
        `}/>
        <Line css={css`
          ${mobile}{
            display: none;
          }
        `}/>
        <Line css={css`
          ${mobile}{
            display: none;
          }
        `}/>
        <Line css={css`
          ${mobile}{
            display: none;
          }
        `}/>
        <div css={css`
          padding-top: 1rem;
          ${mobile}{
            grid-column: span 2;
            }
        `}>
          <h3 css={css`margin-bottom: 1rem`}>Discover</h3>
          <ul>
            {sanitySiteSettings.discoverLinks &&
            sanitySiteSettings.discoverLinks.map(discoverLink =>
            <li key={discoverLink._key}>
                { discoverLink._type === "internalLink" ? <Link to={resolveLink(discoverLink._rawTo)}>{discoverLink.linkText}</Link> : <a href={discoverLink.url} target="_blank" rel="noreferrer">{discoverLink.linkText}</a> }
            </li>)
            }
          </ul>
        </div>
        <div css={css`
                padding-top: 1rem;
                ${mobile}{
                  grid-column: span 2;
                  }
              `}>
            <h3 css={css`margin-bottom: 1rem`}>Follow us</h3>
            <ul>
              {sanitySiteSettings.followUsLinks &&
              sanitySiteSettings.followUsLinks.map(followUsLink =>
              <li key={followUsLink._key}>
                  <a href={followUsLink.url} target="_blank" rel="noreferrer">{followUsLink.linkText}</a>
              </li>)
              }
            </ul>
            <SubscribeForm />
        </div>
        <div css={css`
                padding-top: 1rem;
                ${mobile}{
                  grid-column: span 2;
                  }
              `}>
            <h3 css={css`margin-bottom: 1rem`}>Contact us</h3>
            <div>{sanitySiteSettings.contactEmail}</div>
            <div>{sanitySiteSettings.address}</div>
            <div>{sanitySiteSettings.phoneNumber}</div>
            <div>{sanitySiteSettings.openingHours}</div>
        </div>
        <div css={css`
                padding-top: 1rem;
                ${mobile}{
                  grid-column: span 2;
                  }
              `}>
            <h3 css={css`margin-bottom: 1rem`}>Information</h3>
            <ul>
              {sanitySiteSettings.informationLinks &&
              sanitySiteSettings.informationLinks.map(informationLink =>
              <li key={informationLink._key}>
                { informationLink._type === "internalLink" ? <Link to={resolveLink(informationLink._rawTo)}>{informationLink.linkText}</Link> : <a href={informationLink.url} target="_blank" rel="noreferrer">{informationLink.linkText}</a> }
              </li>)
              }
            </ul>
        </div>
      </Grid>
    </FooterWrap>
    )
  }

  export default Footer
