import React, { useEffect, useLayoutEffect, useState } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import styled from "@emotion/styled"
import Header from "~components/header"
import GlobalStyles from "~styles/global"
import { breakpoints} from "~styles/global"
import Footer from "./footer"
import { css } from "@emotion/react"
import Cookies from "js-cookie"
import "~styles/static.css"
import { useLocation } from "@reach/router"
import { useSiteState } from "~context/siteContext"

const { mobile, tablet } = breakpoints

const Acknowledgment = styled.div`
  :before {
    display: block;
    content: '';
    position: absolute;
    background: #fff;
    width: 2px;
    height: calc(100% + 1.5rem);
    top: -1rem;
    left: calc( -1.5rem - 2px);
  ${tablet}{
    display : none;
    }
  }
`

const AcknowledgmentCondition  = ({ children, acknowledgement }) => {

  const [seenAcknowledgement, setSeen] = useState(true)

  useLayoutEffect(() => {
    const seenCookie = Cookies.get('seen')
    if(seenCookie !== 'true'){
      // Don't show the acknowledgement on preview
      const isPreview = window.location.hostname.includes('gtsb')
      if(!isPreview){
        setSeen(false)
      }
    }
  }, [])

  const hideAck = () => {
    Cookies.set('seen', true, { expires: 2 })
    setSeen(true)
  }

  return(
    <>
      { !seenAcknowledgement ?
        <Acknowledgment css={css`position: relative; margin: 1rem; ${mobile}{ margin: 0.5rem 0rem 1rem}`}>
          <div css={css`position:absolute; width:100%; display: flex; justify-content: flex-end; ${tablet}{ position: relative; float:right; width:auto;}`}>
            <button onClick={() => hideAck()}>
              <svg css={css`
                        overflow: visible;
                        height: 48px;
                        width: 48px;
                        ${mobile}{
                        font-size: 1.33333rem;
                        transform: scale(0.5);
                        }`}>
                <line x1="0" y1="0" x2="48" y2="48" css={css`stroke:#000; stroke-width:2; ${mobile}{ stroke-width:4; }`} />
                <line x1="48" y1="0" x2="0" y2="48" css={css`stroke:#000; stroke-width:2; ${mobile}{ stroke-width:4; }`} />
                <path d="M70 108V191"/>
              </svg>
            </button>
          </div>
          <div css={css`margin-right: calc( 48px + 2rem); max-width: 1200px; ${mobile}{ margin-right: 0; }`}>
            <p css={css`
                    font-size: 2.666667rem;
                    ${mobile}{
                      font-size: 1.33333rem;
                    }`}>

              {acknowledgement}

            </p>
          </div>
        </Acknowledgment>
        : <main>{children}</main>
      }
    </>
  )

}

const Layout = ({ children }) => {

  const { sanitySiteSettings } = useStaticQuery(
    graphql`
      query {
        sanitySiteSettings(id: {eq: "-0f217bb5-f7f6-5420-b7c6-58db2c12b8c7"}) {
          siteTitle
          acknowledgement
        }
      }
    `
  )

  const [siteState, setSiteState] = useSiteState()

  const location = useLocation()

  useEffect(() => {
    const hasFilter = ['/', '/collection'].includes(location.pathname)

    setSiteState(prevState => ({
      ...prevState,
      filterShowing: hasFilter,
      sort: null,
      show: null,
      searchTerm: null,
      loading: false
    }))

  }, [location])

  return (
    <>
      <GlobalStyles />
      <Header siteTitle={sanitySiteSettings.siteTitle || `Title`} />
      <PageWrap>
        <AcknowledgmentCondition children={children} acknowledgement={sanitySiteSettings.acknowledgement}/>
        <Footer />
      </PageWrap>
    </>
  )
}

const PageWrap = styled('div')`
  padding: 0 1rem;
  margin: 227px 0 0 calc(140px + 0.5rem);
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 227px);
  ${tablet}{
    margin: 123px 0 0 0;
    min-height: calc(100vh - 123px);
  }
`

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
