import React, { useEffect, useState } from 'react'
import { useSiteState } from "~context/siteContext"
import { css, Global } from "@emotion/react"

export const CompactLogo = ({ className }) => (
  <div className={className}>
  <div css={css`
      margin: 0 8px 5px 8px;
      padding: 0 5px 0;
      height: 2px;
      background: var(--black);
    `} />
  <svg viewBox="0 0 71 83" fill="none" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none">
    <path d="M1 0L1 83" stroke="black" strokeWidth="2" strokeMiterlimit="10" vectorEffect="non-scaling-stroke"/>
    <path d="M70 0V83" stroke="black" strokeWidth="2" strokeMiterlimit="10" vectorEffect="non-scaling-stroke"/>
    <path d="M16 80L56 2" stroke="black" strokeWidth="2" strokeMiterlimit="10" vectorEffect="non-scaling-stroke"/>
  </svg>
  <div css={css`
      margin: 5px 8px 0 8px;
      padding: 0 5px 0;
      height: 2px;
      background: var(--black);
      position: relative;
      z-index: 2;
    `} />
  </div>
)

export const Logo = ({ letter }) => {

  const [siteState] = useSiteState()

  const letters = {
    all: [1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1],
    a: [0,0,0,0,1,1,0,1,1,0,0,0,1,0,1,0],
    h: [0,0,1,1,1,1,0,0,0,0,0,0,1,1,0,0],
    w: [0,0,1,1,1,1,0,0,1,1,0,0,0,0,0,0],
    d: [1,1,0,0,1,1,0,0,0,0,1,1,0,0,1,1],
    p: [1,1,0,0,0,1,0,0,0,0,1,1,1,0,1,1],
    c: [0,0,1,1,0,0,0,0,0,0,1,1,0,0,1,1],
    l: [0,0,1,1,0,0,0,0,0,0,1,1,0,0,0,0],
    s: [0,0,0,0,1,0,1,0,0,0,1,1,1,0,1,1],
    n: [0,0,1,1,1,1,1,0,0,1,0,0,0,0,0,0],
    none: [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
    four: [1,1,0,1,0,0,0,0,0,0,0,0,1,1,0,0],
    zero: [0,0,1,1,1,1,0,1,1,0,1,1,0,0,1,1],
  }
  const l = letters[letter] ?? letters['all'];

  const [frame, setFrame] = useState(0)

  useEffect(() => {
    // start the frame changing
    window.setInterval(() => {
      const lastFrame = 3
      setFrame(prevFrame => (prevFrame === lastFrame ? 0 : prevFrame + 1 ))
    }, 200)
  }, [])

  if(siteState.loading){
    return(
      <>
      <Global styles={css`
        nav{
          pointer-events: none;
        }
      `}/>
      <svg viewBox="0 0 140 200" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="black" strokeWidth="2" strokeMiterlimit="10">
        {frame === 2 && <path d="M70 108V191"/>}
        {frame === 2 &&<path d="M70 9V92"/>}
        {l[2] && <path d="M1 108V191"/>}
        {l[3] && <path d="M1 9V93"/>}
        {l[4] && <path d="M139 108V191"/>}
        {l[5] && <path d="M139 9V93"/>}
        {frame === 1 && <path d="M55 89L15 11"/>}
        {frame === 3 && <path d="M85 89L125 11"/>}
        {frame === 3 && <path d="M55 111L15 189"/>}
        {frame === 1 && <path d="M85 111L125 189"/>}
        {l[10] && <path d="M78 199H131"/>}
        {l[11] && <path d="M9 199H62"/>}
        {frame === 0 && <path d="M78.1 100H131.1"/>}
        {frame === 0 &&<path d="M9 100H62"/>}
        {l[14] && <path d="M78.1 1H131.1"/>}
        {l[15] && <path d="M9 1H62"/>}
      </svg>
      </>
    )
  }
  
  return(
    <>
    <svg viewBox="0 0 140 200" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="black" strokeWidth="2" strokeMiterlimit="10">
      {l[0] && <path d="M70 108V191"/>}
      {l[1] && <path d="M70 9V92"/>}
      {l[2] && <path d="M1 108V191"/>}
      {l[3] && <path d="M1 9V93"/>}
      {l[4] && <path d="M139 108V191"/>}
      {l[5] && <path d="M139 9V93"/>}
      {l[6] && <path d="M55 89L15 11"/>}
      {l[7] && <path d="M85 89L125 11"/>}
      {l[8] && <path d="M55 111L15 189"/>}
      {l[9] && <path d="M85 111L125 189"/>}
      {l[10] && <path d="M78 199H131"/>}
      {l[11] && <path d="M9 199H62"/>}
      {l[12] && <path d="M78.1 100H131.1"/>}
      {l[13] && <path d="M9 100H62"/>}
      {l[14] && <path d="M78.1 1H131.1"/>}
      {l[15] && <path d="M9 1H62"/>}
    </svg>
    </>
    
  )
}

export const PlayButton = ({ className }) => (
  <svg viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path fillRule="evenodd" clipRule="evenodd" d="M50 98C76.5097 98 98 76.5097 98 50C98 23.4903 76.5097 2 50 2C23.4903 2 2 23.4903 2 50C2 76.5097 23.4903 98 50 98ZM50 100C77.6142 100 100 77.6142 100 50C100 22.3858 77.6142 0 50 0C22.3858 0 0 22.3858 0 50C0 77.6142 22.3858 100 50 100Z" fill="currentColor"/>
    <path d="M67 50L40 65.5884L40 34.4115L67 50Z" fill="currentColor"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M40.5 33.5455L69 50L39 67.3205L39 32.6794L40.5 33.5455ZM41 36.1435L41 63.8564L65 50L41 36.1435Z" fill="currentColor"/>
  </svg>
)
