import React from "react"
import { Global, css } from "@emotion/react"
import styled from "@emotion/styled"

export const Grid = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: 1rem;
`

export const breakpoints =  {
  mobile: '@media (max-width: 768px)',
  tablet: '@media (max-width: 1024px)',
  desktop: '@media (max-width: 1230px)',
  desktopUp: '@media (min-width: 1025px)'
}
const { mobile } = breakpoints

export const typeColors = {
  work: 'var(--purple)',
  artist: 'var(--orange)',
  event: 'var(--green)',
  article: 'var(--magenta)',
  program: 'var(--yellow)',
  writer: 'var(--blue)',
  curator: 'var(--darkgreen)',
  text: 'var(--pink)',
  textDoc: 'var(--pink)',
  page: 'var(--white)',
}

const GlobalStyles = () => (
  <>
  <Global styles={reset} />
  <Global styles={styles} />
  </>
)

const styles = css`

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: inherit;
    font-size: inherit;
  }

  :root{
    --white: #ffffff;
    --black: #000000;
    --grey: #E5E5E5;
    --black: #000000;
    --green: #9EFF00;
    --purple: #AE9AFF;
    --magenta: #DA8CFF;
    --orange: #F97700;
    --yellow: #FBFF39;
    --blue: #39F3FF;
    --darkgreen: #00EA3E;
    --pink: #F79DFF;
    --darkgrey: #999999;
  }

  h1, .h1{
    font-size: 48px;
    line-height: 1.3;
  }

  h2, .h2{
    font-size: 24px;
    line-height: 1.2;
  }

  h3, .h3{
    font-size: 14px;
    line-height: 1.2;
  }

  h4, .h4{
    font-size: 14px;
    line-height: 1.2;
    text-transform: uppercase;
  }

  h5, .h5{
    font-size: 12px;
    line-height: 1.2;
  }

  html, body, .p{
    font-size: 18px;
    line-height: 1.2;
  }

  p{
    margin-bottom: 0.8em;
    &:last-child{
      margin-bottom: 0;
    }
    a:not(.internal-link){
      text-shadow: 0 4px 4px #999999;
    }
  }
  body {
    color: var(--black);
    font-family: 'Standard', sans-serif;
    line-height: 1.3;
    transition: background-color 1s;
  }
  * {
    &:focus{
      outline-color: var(--pink);
      outline-offset: 2px;
    }
  }
  .inline-list, .comma-list {
    list-style-type: none;
  }
  .inline-list li, .comma-list li{
    list-style-type: none;
    display: inline;
  }
  .inline-list li {
    margin-right: 9px;
  }
  .comma-list li::after {
    content: ", ";
  }
  .comma-list li:last-child::after {
    content: "";
  }
  .inline-ul {
    display: inline;
  }
  .dash-list {
    list-style-type: none;
    li:before {
      content: "-";
      margin-right: 0.2rem
    }
  }
  .highlight {
    text-shadow: 0px 4px 4px;
  }
  .mb-1 {
    margin-bottom: 1rem;
  }
  .italic {
    font-style: italic;
  }
  button.primary {
    display: block;
    width: 100%;
    height: 100%;
    min-height: 48px;
    ${mobile}{
      font-size: 14px;
    }
  }
  .text-center {
    text-align: center;
  }
  /* Masonry Css */
  .m-grid {
    display: -webkit-box; /* Not needed if autoprefixing */
    display: -ms-flexbox; /* Not needed if autoprefixing */
    display: flex;
    width: auto;
  }
  .m-grid_column {
    background-clip: padding-box;
  }

`
const reset = css`

  body {
    margin: 0px;
    min-height: 100%;
  }
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    padding: 0;
  }

  ul, ol, blockquote {
    margin: 0;
    padding: 0;
  }

  li {
    margin: 0.5em 0;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  * {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color:  rgba(255, 255, 255, 0);
  }

  button{
  -webkit-appearance: none;
  border: none;
  background: none;
  font-size: inherit;
  font-family: inherit;
  cursor: pointer;
  padding: 0;
  margin: 0;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 500px white inset !important;
  }
`

export default GlobalStyles
